<template>
  <div :class="`message-card-files`">
    <CreatorPostCard v-if="post" :post="post" />
    <div :class="`MessageCardFiles ${isGte2Files && '__gte2'}`">
      <div v-for="(file, index) in message.files" :key="index" :class="`MessageFile __file`">
        <img v-if="mediaType(file) === 'image'" :src="file.src" alt="Image" @click="viewFiles(index)" />
        <video
          v-else-if="mediaType(file) === 'video'"
          width="100%"
          nocontrols
          playsinline
          controlslist="nodownload"
          :poster="file.files && file.files[index]"
          @click="viewFiles(index)"
        >
          <source :src="`${file.src}`" />
        </video>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["message", "post"],
  components: {
    CreatorPostCard: () => import("@/components/creator/CreatorPostCard.vue"),
  },
  methods: {
    viewFiles: function(index) {
      const media = this.message.files;
      const fisrt = media[index];
      const current = media.filter((item) => item.src !== fisrt.src);
      current.unshift(fisrt);
      this.Media(current);
    },
  },
  computed: {
    isGte2Files: function() {
      return this.message.files.length >= 2;
    },
  },
};
</script>

<style lang="scss">
.message-card-files {
  position: relative;
  img,
  video {
    max-width: 100%;
    border-radius: $mradius;
    margin: 0 0 $mpadding/2 0;
  }

  .feed-post {
    margin: 0 0 $mpadding/1.5 0;
    font-size: 90%;
  }

  .creator-post-options {
    display: none;
  }

  .feed-post .user-avatar {
    @include sizeFixed($mpadding * 3);
  }

  .feed-post .creator-post-header-user {
    font-size: 90%;
  }

  .MessageCardFiles.__gte2 {
    columns: 2;
    column-gap: $mpadding/2;
  }

  .MessageFile {
    cursor: pointer;
  }
}
</style>
